import React, { useEffect, useState } from 'react'
import { useTimer, useTypedSelector } from '../../hooks'
import GameWinMessage from './GameWinMessage'
import GameControlBar from './GameControlBar'
import GameContext from './GameContext'
import { Container } from './styles'
import GameCards from './GameCards'
import { GameCardType } from '../../types/GameConfig'
import { useHistory } from 'react-router-dom'

const Game: React.FC = () => {
  const history = useHistory()
  const [iconList, setIconList] = useState<GameCardType[]>([])
  const [iconFoundList, setIconFoundList] = useState<GameCardType[]>([])
  const [firstSelectedCard, setFirstSelectedCard] = useState(-1)
  const [secondSelectedCard, setSecondSelectedCard] = useState(-1)
  const [isShowingWinModal, setIsShowingWinModal] = useState(false)
  const [isCheckingCards, setIsCheckingCards] = useState(false)
  const [isPaused, setIsPaused] = useState(true)
  const [countdown, setCountdown] = useState(3)

  const { difficulty } = useTypedSelector(({ GameConfig }) => GameConfig)

  const {
    milisecondsText,
    hoursText,
    minutesText,
    secondsText,
    onStartTimer,
    onStopTimer,
    onPauseTimer,
  } = useTimer()

  // Check if cardList is in localStorage
  useEffect(() => {
    const cachedCardList = localStorage.getItem('cardList')
    if (!cachedCardList) {
      // Card list does not exist in localStorage, navigate to splash route
      history.push('/splash')
    }
  }, [history])

  return (
    <GameContext.Provider
      value={{
        difficulty,
        isPaused,
        setIsPaused,
        iconList,
        setIconList,
        iconFoundList,
        setIconFoundList,
        firstSelectedCard,
        setFirstSelectedCard,
        secondSelectedCard,
        setSecondSelectedCard,
        isCheckingCards,
        setIsCheckingCards,
        isShowingWinModal,
        setIsShowingWinModal,
        milisecondsText,
        hoursText,
        minutesText,
        secondsText,
        onStartTimer,
        onStopTimer,
        onPauseTimer,
        countdown,
        setCountdown,
      }}
    >
      <Container>
        <GameControlBar />
        <GameCards />
        <GameWinMessage />
      </Container>
    </GameContext.Provider>
  )
}

export default Game
