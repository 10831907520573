import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  OutlinedButton,
  SloganContainer,
  StyledInput,
  VoteButton,
} from './styles'
import logo1x from '../../assets/erkado-logo.png'
import logo2x from '../../assets/erkado-logo@2x.png'
import gra from '../../assets/gra.png'
import dalmierz from '../../assets/dalmierz.png'
import star from '../../assets/star.png'
import AlertModal from '../../components/AlertModal'
import axios from 'axios'
import { NormalButton } from '../Register/styles'

const Home: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [modalMessage, setModalMessage] = useState<string>('')
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [busy, setBusy] = useState<boolean>(false)

  const onVote = (): void => {
    history.push('/vote')
  }

  const onPlay = (): void => {
    history.push('/splash')
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // Validate email using regular expression
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!email || !emailRegex.test(email)) {
      setModalMessage('Niepoprawny adres e-mail')
      setIsShowingModal(true)
    } else {
      try {
        setBusy(true)
        const loginResponse = await axios.post(`${API_URL}/game/login`, {
          username: email,
        })

        if (loginResponse.data.token) {
          localStorage.setItem('erkU', loginResponse.data.token)
          history.push('/splash')
          setBusy(false)
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                setModalMessage('Osoba o takim adresie nie istnieje w bazie.')
                setIsShowingModal(true)
                break

              default:
                setModalMessage('Ups... coś poszło nie tak. Spróbuj ponownie.')
                setIsShowingModal(true)
                break
            }
          }
        }
        setBusy(false)
      }
    }
  }

  const decodeToken = (token: string) => {
    const base64Url = token.split('.')[1] // JWTs are typically in the format: header.payload.signature
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/') // Replace URL-safe characters
    const decoded = decodeURIComponent(escape(atob(base64))) // Decode and convert to a string

    return JSON.parse(decoded) // Parse the JSON payload
  }

  useEffect(() => {
    const token = localStorage.getItem('erkU')
    if (token) {
      setIsUserLoggedIn(true)

      const decodedPayload = decodeToken(token)
      setUsername(decodedPayload.username)
    }
    localStorage.removeItem('erkT')
    localStorage.removeItem('erkV')
  }, [])

  return (
    <Container>
      <img
        src={logo1x}
        srcSet={`${logo1x} 1x, ${logo2x} 2x`}
        alt=""
        style={{ maxWidth: '100%', marginTop: '30px', marginBottom: '5px' }}
      />
      <SloganContainer>
        <div style={{ marginRight: '15px' }}>
          <span style={{ color: '#C51431' }}>ZAGŁOSUJ</span>
          <br />
          na najpiękniejsze drzwi!
        </div>
        <img src={star} alt="" style={{}} />
      </SloganContainer>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          height: 'calc(100vh - 340px)',
          justifyContent: 'space-around',
          marginTop: '20px',
        }}
      >
        <img
          src={gra}
          alt=""
          style={{ position: 'absolute', height: '100%', right: '-200px' }}
        />
        <img
          src={dalmierz}
          alt=""
          style={{ position: 'absolute', height: '100%', left: '-60px' }}
        />
      </div>
      {!isUserLoggedIn && (
        <>
          <VoteButton onClick={onVote}>Głosuję i gram o dalmierz</VoteButton>
          <div
            style={{
              textAlign: 'center',
              marginTop: '15px',
              marginBottom: '5px',
            }}
          >
            Jeśli jesteś już zarejestrowany, podaj swój adres.
          </div>
          <form onSubmit={handleSubmit}>
            <StyledInput
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
              style={{ marginRight: 5 }}
            />
            <OutlinedButton type="submit" disabled={busy}>
              Zaloguj
            </OutlinedButton>
          </form>
        </>
      )}
      {isUserLoggedIn && (
        <div style={{ textAlign: 'center' }}>
          Jesteś zalogowany jako:
          <br />
          <strong style={{ color: '#C51431' }}>{username}</strong>
          <br />
          <NormalButton onClick={onPlay} style={{ marginTop: 10 }}>Graj</NormalButton>
          <OutlinedButton
            onClick={() => {
              localStorage.removeItem('erkU')
              localStorage.removeItem('erkA')
              localStorage.removeItem('erkB')
              localStorage.removeItem('erkF')
              localStorage.removeItem('erkP')
              localStorage.removeItem('erkWin')
              setIsUserLoggedIn(false)
              setUsername('')
            }}
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            Wyloguj
          </OutlinedButton>
        </div>
      )}
      <AlertModal isShowing={isShowingModal}>
        <>
          <p>{modalMessage}</p>
          <br />
          <OutlinedButton onClick={() => setIsShowingModal(false)}>
            Zamknij
          </OutlinedButton>
        </>
      </AlertModal>
    </Container>
  )
}

export default Home
