import React, { useState } from 'react'
import help1x from '../../assets/help.png'
import help2x from '../../assets/help@2x.png'
import details1x from '../../assets/details.png'
import details2x from '../../assets/details@2x.png'
import AlertModal from '../AlertModal'
import { OutlinedButton } from '../../pages/Home/styles'

const HelpIcons: React.FC = () => {
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(null)

  const rules = (
    <p style={{ fontSize: '85%', lineHeight: 1.7 }}>
      <strong>
        ZNAJDŹ TAKIE SAME PARY DRZWI ERKADO W JAK NAJKRÓTSZYM CZASIE I WYGRAJ
        NAGRODĘ.
      </strong>
      <br />
      <br />
      <ul style={{ marginLeft: '10px', textAlign: 'left' }}>
        <li>
          Gra polega na odsłanianiu dwóch kart i odnalezieniu takich samych par
          drzwi Erkado.
          <ul
            style={{
              marginLeft: '20px',
              fontSize: '90%',
              fontStyle: 'italic',
            }}
          >
            <li>Jeśli karty są identyczne, wtedy pozostają odsłonięte.</li>
            <li>
              Jeśli karty nie pasują do siebie, wtedy zakrywają się a Ty
              próbujesz dalej.
            </li>
            <li>Gra kończy się, gdy wszystkie karty zostaną odkryte.</li>
          </ul>
        </li>
        <li>
          Twój wynik to czas liczony w sekundach od momentu startu gry do
          momentu odkrycia wszystkich kart.
        </li>
        <li>
          Możesz grać dowolną ilość razy, w rankingu liczy się Twój najlepszy
          wynik w danej turze.
        </li>
        <li>
          <strong>
            W każdej turze do wgrania - 3 laserowe dalmierze dla Uczestników,
            którzy najszybciej odkryją wszystkie karty.
          </strong>
        </li>
        <li>
          Konkurs składa się z 8 tur:
          <ol
            style={{
              marginLeft: '20px',
              fontSize: '90%',
              fontStyle: 'italic',
            }}
          >
            <li>
              07.11.2023 - gra trwa od godz. 10.00 do 13.00 - rozdanie nagród
              godz.13.05
            </li>
            <li>
              07.11.2023 - gra trwa od godz. 13.00 do 16.00 - rozdanie nagród
              godz.16.05
            </li>
            <li>
              08.11.2023 - gra trwa od godz. 10.00 do 13.00 - rozdanie nagród
              godz.13.05
            </li>
            <li>
              08.11.2023 - gra trwa od godz. 13.00 do 16.00 - rozdanie nagród
              godz.16.05
            </li>
            <li>
              09.11.2023 - gra trwa od godz. 10.00 do 13.00 - rozdanie nagród
              godz.13.05
            </li>
            <li>
              09.11.2023 - gra trwa od godz. 13.00 do 16.00 - rozdanie nagród
              godz.16.05
            </li>
            <li>
              10.11.2023 - gra trwa od godz. 10.00 do 13.00 - rozdanie nagród
              godz.13.05
            </li>
            <li>
              10.11.2023 - gra trwa od godz. 13.00 do 16.00 - rozdanie nagród
              godz.16.05
            </li>
          </ol>
        </li>
        <li>Możesz otrzymać tylko 1 nagrodę w całym konkursie.</li>
        <li>
          Aby zapisać swój wynik i odebrać nagrodę musisz zarejestrować się,
          podając swoje dane oraz akceptując wymagane zgody regulaminowe.
        </li>
      </ul>
    </p>
  )

  return (
    <>
      <img
        src={help1x}
        srcSet={`${help1x} 1x, ${help2x} 2x`}
        alt=""
        style={{ maxWidth: '100%', marginRight: '10px' }}
        onClick={() => {
          setModalContent(rules)
          setIsShowingModal(true)
        }}
      />
      <img
        src={details1x}
        srcSet={`${details1x} 1x, ${details2x} 2x`}
        alt=""
        style={{ maxWidth: '100%' }}
        onClick={() => {
          window.open('https://gwiazdyerkado.pl/regulamin-erkado.pdf')
        }}
      />
      <AlertModal
        isShowing={isShowingModal}
        onCloseModal={() => setIsShowingModal(false)}
      >
        <>
          {modalContent}
          <br />
          <OutlinedButton onClick={() => setIsShowingModal(false)}>
            Zamknij
          </OutlinedButton>
        </>
      </AlertModal>
    </>
  )
}

export default HelpIcons
