import React, { useEffect, useState } from 'react'
import { Container, OutlinedButton, RankingItem } from './styles'
import Header from '../../components/Header'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Loading from '../../components/Loading'
import AwardsTimer from '../../components/AwardsTimer'

type RankingResult = {
  rankPlace: number
  firstname: string
  elapsedTime: number
}

const Ranking: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL

  const [loading, setLoading] = useState(true)

  const [originalString, setOriginalString] = useState<string>('')
  const [awardDates, setAwardDates] = useState<string[]>([])
  const [elapsedTime, setElapsedTime] = useState<number | null>(null)
  const [rankingResults, setRankingResults] = useState<RankingResult[]>([])
  const history = useHistory()

  const onPlayAgain = (): void => {
    localStorage.removeItem('erkT')
    history.push('/splash')
  }

  const onLogout = (): void => {
    localStorage.removeItem('erkU')
    localStorage.removeItem('erkA')
    localStorage.removeItem('erkB')
    localStorage.removeItem('erkF')
    localStorage.removeItem('erkP')
    localStorage.removeItem('erkWin')
    history.push('/')
  }

  useEffect(() => {
    const loadData = async (elapsedTime: number) => {
      try {
        const cards = localStorage.getItem('erkC')
        const gameDate = localStorage.getItem('erkD')
        const token = localStorage.getItem('erkU')

        if (cards && gameDate && token) {
          const userGameResponse = await axios.post(
            `${API_URL}/api/user-game`,
            {
              gameDate,
              cards: JSON.parse(cards),
              elapsedTime,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          )

          if (userGameResponse.data.success === true) {
            localStorage.removeItem('erkC')
            localStorage.removeItem('erkD')

            localStorage.setItem(
              'erkB',
              userGameResponse.data.userBest.toString(),
            )
            localStorage.setItem(
              'erkP',
              userGameResponse.data.rankPlace.toString(),
            )
            localStorage.setItem(
              'erkA',
              userGameResponse.data.rankingId.toString(),
            )
            localStorage.setItem('erkF', userGameResponse.data.userFirstname)
          } else {
            localStorage.setItem(
              'erkWin',
              userGameResponse.data.hasWon.toString(),
            )
          }
        }

        const response = await axios.get(`${API_URL}/api/main/ranking/list`)

        setAwardDates(
          response.data.rankings.map(
            (ranking: { awardDate: string }) => ranking.awardDate,
          ),
        )

        const rankingReponse = await axios.get(`${API_URL}/api/main/ranking`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        setRankingResults(rankingReponse.data.results)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    const encodedString = localStorage.getItem('erkT')
    const token = localStorage.getItem('erkU')
    if (encodedString && token) {
      const decoded = atob(encodedString)
      setOriginalString(decoded.slice(3))
      const milliseconds = timeStringToMilliseconds(decoded)
      setElapsedTime(milliseconds)
      loadData(milliseconds)
    } else {
      history.push('/')
    }
  }, [])

  const timeStringToMilliseconds = (timeString: string) => {
    const [hours, minutes, secondsAndMillis] = timeString.split(':')
    const [seconds, milliseconds] = secondsAndMillis.split('.')

    const totalMilliseconds =
      parseInt(hours, 10) * 3600000 + // 1 hour = 3600000 milliseconds
      parseInt(minutes, 10) * 60000 + // 1 minute = 60000 milliseconds
      parseInt(seconds, 10) * 1000 + // 1 second = 1000 milliseconds
      parseInt(milliseconds, 10)

    return totalMilliseconds
  }

  const formatDuration = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000)
    // const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60
    const millisecondsRemaining = milliseconds % 1000

    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(
      seconds,
    ).padStart(2, '0')}.${String(millisecondsRemaining).padStart(2, '0')}`

    return formattedTime
  }

  return (
    <Container>
      <Header />
      <h3 style={{ fontWeight: 'normal', textAlign: 'center' }}>
        Najbliższe podsumowanie rankingu i przyznanie nagród:
        <br />
        <span style={{ color: '#C51431' }}>
          <AwardsTimer awardDates={awardDates} />
        </span>
      </h3>
      <br />
      <h3 style={{ fontWeight: 'normal', textAlign: 'center' }}>
        Twój wynik:{' '}
        <strong style={{ color: '#C51431' }}>{originalString}</strong>
      </h3>
      {localStorage.getItem('erkA') &&
        localStorage.getItem('erkP') &&
        localStorage.getItem('erkB') && (
          <h5 style={{ fontWeight: 'normal', textAlign: 'center' }}>
            Twój najlepszy wynik w {localStorage.getItem('erkA')} turze:{' '}
            <strong style={{ color: '#C51431' }}>
              {formatDuration(parseInt(localStorage.getItem('erkB') || ''))}{' '}
              (miejsce {localStorage.getItem('erkP')})
            </strong>
          </h5>
        )}
      <br />
      {localStorage.getItem('erkWin') &&
        localStorage.getItem('erkWin') === 'true' && (
          <div
            style={{
              padding: '6px 16px',
              color: 'rgb(102, 60, 0)',
              border: '1px solid rgb(255, 152, 0)',
              background: '#fff',
              borderRadius: '12px',
              margin: '0 15px 20px 15px',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            Ze względu na wygraną w poprzednich turach, Twoje wyniki nie
            zapisują się w aktualnym rankingu.
          </div>
        )}
      <h3 style={{ fontWeight: 'normal', textAlign: 'center' }}>
        Ranking 10 najszybszych graczy
      </h3>
      {loading && <Loading />}
      {!loading && (
        <div style={{ marginTop: 5, width: '100%', textAlign: 'center' }}>
          {rankingResults
            .slice(
              0,
              parseInt(localStorage.getItem('erkP') || '0') > 10 ? 9 : 10,
            )
            .map((result: RankingResult) => (
              <RankingItem
                key={`result-${result.rankPlace}`}
                style={
                  result.rankPlace ===
                  parseInt(localStorage.getItem('erkP') || '0')
                    ? {
                        background: '#C51431',
                        borderColor: '#C51431',
                        color: '#fff',
                      }
                    : {}
                }
              >
                <div>
                  {result.rankPlace}. {result.firstname}
                </div>
                <div>{formatDuration(result.elapsedTime)}</div>
              </RankingItem>
            ))}
          {parseInt(localStorage.getItem('erkP') || '0') > 10 && (
            <>
              <div style={{ marginBottom: 7 }}>...</div>
              <RankingItem
                style={{
                  background: '#C51431',
                  borderColor: '#C51431',
                  color: '#fff',
                }}
              >
                <div>
                  {localStorage.getItem('erkP')}. {localStorage.getItem('erkF')}
                </div>
                <div>
                  {formatDuration(parseInt(localStorage.getItem('erkB') || ''))}
                </div>
              </RankingItem>
            </>
          )}
          <OutlinedButton
            style={{
              width: '80%',
              maxWidth: '600px',
              marginBottom: 20,
              marginTop: 10,
            }}
            onClick={onPlayAgain}
          >
            Chcę zagrać jeszcze raz
          </OutlinedButton>
          <div>
            <OutlinedButton
              onClick={onLogout}
              style={{ marginBottom: 20, fontSize: '80%' }}
            >
              Wyloguj
            </OutlinedButton>
          </div>
        </div>
      )}
    </Container>
  )
}

export default Ranking
