import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CardsContainer, PlayButton, PlayButtonContainer } from './styles'
import axios from 'axios'
import { GameCardType } from '../../types/GameConfig'
import { CardItem } from '../Game/GameCards/styles'
import AlertModal from '../../components/AlertModal'
import GameControlBar from '../Game/GameControlBar'
import timer from '../../assets/timer.png'
import AwardsTimer from '../../components/AwardsTimer'
import Loading from '../../components/Loading'

const SplashScreen: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const history = useHistory()
  const [loading, setLoading] = useState(true) // Loading state
  const [timerLoading, setTimerLoading] = useState(true) // Loading state
  const [progress, setProgress] = useState(0) // Progress state
  const [awardDates, setAwardDates] = useState<string[]>([])

  const onPlay = (): void => {
    history.push('/game')
  }

  useEffect(() => {
    localStorage.removeItem('erkT')
    const token = localStorage.getItem('erkU')
    const optionId = localStorage.getItem('erkV')

    if (!token && !optionId) {
      history.push('/')
    }
  }, [])

  useEffect(() => {
    const loadImages = (cardList: Pick<GameCardType, 'id' | 'name'>[]) => {
      // Calculate the total number of images to load
      const totalImages = cardList.length
      let loadedImages = 0

      cardList.forEach((card: Pick<GameCardType, 'id' | 'name'>) => {
        // Check if the data is already in localStorage
        const cachedData = localStorage.getItem(`erk_card_${card.id}`)

        if (cachedData) {
          // Data is in localStorage, parse it
          const cachedCard = JSON.parse(cachedData)

          // Compare the name of the cached card with the name of the current card
          if (cachedCard.name === card.name) {
            // Names match, use the cached data
            loadedImages++
            checkLoadingState(loadedImages, totalImages)
          } else {
            // Names don't match, make the API request
            fetchCardImage(card)
          }
        } else {
          // Data is not in localStorage, make the API request
          fetchCardImage(card)
        }
      })

      function fetchCardImage(card: Pick<GameCardType, 'id' | 'name'>) {
        axios
          .get(`${API_URL}/api/main/card/image/${card.id}`)
          .then((response) => {
            const cardWithImages = {
              id: card.id,
              name: card.name,
              img1: {
                content: response.data.firstImageContent,
                mimeType: response.data.firstImageMimeType,
                size: response.data.firstImageSize,
              },
              img2: response.data.secondImageContent
                ? {
                    content: response.data.secondImageContent,
                    mimeType: response.data.secondImageMimeType,
                    size: response.data.secondImageSize,
                  }
                : null,
            }

            // Save the data to localStorage
            localStorage.setItem(
              `erk_card_${card.id}`,
              JSON.stringify(cardWithImages),
            )

            loadedImages++
            checkLoadingState(loadedImages, totalImages)
          })
          .catch((error) => {
            console.error(`Error fetching image for card ${card.id}:`, error)
            loadedImages++
            checkLoadingState(loadedImages, totalImages)
          })
      }

      function checkLoadingState(loadedImages: number, totalImages: number) {
        const currentProgress = (loadedImages / totalImages) * 100
        setProgress(currentProgress)

        if (loadedImages === totalImages) {
          setLoading(false) // Set loading to false when all requests are complete
        }
      }
    }

    axios
      .get(`${API_URL}/api/main/card/list`)
      .then((response) => {
        localStorage.setItem('cardList', JSON.stringify(response.data.cards))
        loadImages(response.data.cards)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setLoading(false) // Set loading to false in case of an error
      })

    axios
      .get(`${API_URL}/api/main/ranking/list`)
      .then((response) => {
        setAwardDates(
          response.data.rankings.map(
            (ranking: { awardDate: string }) => ranking.awardDate,
          ),
        )
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
      .finally(() => {
        setTimerLoading(false)
      })
  }, [API_URL])

  return (
    <div style={{ height: '100%' }}>
      <GameControlBar hasTimer={false} />
      <CardsContainer>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
          (_, index: number): React.ReactNode => {
            const numOfCardsInEachLine = 16 / 4
            const isLastRow = index >= 16 - numOfCardsInEachLine

            return (
              <CardItem
                key={index}
                isVisible={true}
                wasFound={false}
                isShowingFrontFace={false}
                disabled={true}
                numOfCardsInEachLine={16 / 4}
                isLastRow={isLastRow}
              ></CardItem>
            )
          },
        )}
      </CardsContainer>
      <AlertModal isShowing={true}>
        <img src={timer} alt="" style={{ height: '50px' }} />
        <p>
          <strong>Najbliższe rozdanie nagród za:</strong>
          <br />
          {timerLoading && <Loading />}
          {!timerLoading && <AwardsTimer awardDates={awardDates} />}
          <br />
          <br />
          Znajdź pary drzwi w jak najkrótszym czasie i wygraj dalmierz cyfrowy!
        </p>
        <PlayButtonContainer>
          {loading && (
            <div>
              <p>Wczytuję... {progress.toFixed(2)}%</p>
              <progress value={progress} max="100" />
            </div>
          )}
          {!loading && <PlayButton onClick={onPlay}>Start</PlayButton>}
        </PlayButtonContainer>
      </AlertModal>
    </div>
  )
}

export default SplashScreen
