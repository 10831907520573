import React, { useContext, useEffect } from 'react'
import useClearGameState from '../useClearGameState'
import GameContext from '../GameContext'
import {
  Container,
  LeftSideContainer,
  RightSideContainer,
  Timer,
  TimerText,
  CenterContainer,
} from './styles'
import logo1x from '../../../assets/erkado-logo.png'
import logo2x from '../../../assets/erkado-logo@2x.png'
import { useHistory } from 'react-router-dom'
import HelpIcons from '../../../components/HelpIcons'

type GameControlBarType = {
  hasTimer?: boolean
}

const GameControlBar: React.FC<GameControlBarType> = ({ hasTimer = true }) => {
  const { setIsPaused, hoursText, minutesText, secondsText, onStartTimer } =
    useContext(GameContext)

  const onClearGameState = useClearGameState()
  const history = useHistory()

  const onLogoClick = (): void => {
    history.push('/')
  }

  useEffect(() => {
    if (hasTimer) {
      setIsPaused(false)
      onClearGameState()
      onStartTimer()
    }
  }, [hasTimer])

  useEffect(() => {
    localStorage.removeItem('erkT')
    const token = localStorage.getItem('erkU')
    const optionId = localStorage.getItem('erkV')

    if (!token && !optionId) {
      history.push('/')
    }
  }, [])

  return (
    <Container>
      <LeftSideContainer>
        <img
          src={logo1x}
          srcSet={`${logo1x} 1x, ${logo2x} 2x`}
          alt=""
          style={{ maxWidth: '100%' }}
          onClick={onLogoClick}
        />
      </LeftSideContainer>
      <CenterContainer>
        {hasTimer && (
          <Timer>
            Twój czas:
            <TimerText>{`${minutesText}:${secondsText}`}</TimerText>
          </Timer>
        )}
      </CenterContainer>
      <RightSideContainer>
        <HelpIcons />
      </RightSideContainer>
    </Container>
  )
}

export default GameControlBar
